import { Box, Grid, Typography, useTheme } from "@mui/material"
import { PlayerCharacter, PlayerCharacterDisplay } from "../../model/character";
import styled from 'styled-components';
import { CharacterContext } from "../../model/characterContext";
import { ReactNode, useContext } from "react";
import { ArmorAtZeroStat, ArmorStat, CombatOrder, Defence, EnduranceMaxStat, GritReroll, Recovery } from "../../model/statsConstant";
import ButtonFlat from "../../components/ButtonFlat";

import { ReactComponent as CombatOrderIcon } from "../../components/icons/raw/Icon_Combat_order.svg";
import { ReactComponent as EnduranceDamageIcon } from "../../components/icons/raw/Icon_End_Damage.svg";
import LabelBox from "../../components/LabelBox";

const OuterBox =  styled.div`
    padding: 4px;
    background-color: #23232d;
    flex-grow: 1;
    height: 100%;
`;
    
const InnerBox =  styled.div`
    position: relative;
    height:100%;
`;
    
const Label =  styled.div`
    text-align: center;
    color: #fff;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    font-size:
    line-height: 1em;
`;
    
const EnduranceBar = styled.div<{ amount: number }>`
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${props => props.amount}%;
    background-color: #dd0000;
`;

interface EnduranceDamageViewProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setDamage: (value: number) => void;
}

const CombatStatsView = (props: EnduranceDamageViewProps) => {
    const characterContext = useContext(CharacterContext)!;
    const theme = useTheme();
    const { character, characterDisplay, setDamage } = props;

    const strength = characterDisplay.attributes.find(a => a.id == "str")?.withDamage ?? 0;

    const enduranceMax = 10 + (strength ?? 0) * 5 + characterContext.getAdjustment(EnduranceMaxStat.id);
    const endurance = enduranceMax - (character.enduranceDamageTaken ?? 0);
    const recovery = (characterDisplay.attributes.find(a => a.id == "grit")?.current ?? 0) + characterContext.getAdjustment(Recovery.id); // Change to current

    const grit = characterDisplay.attributes.find(a => a.id == "grit")?.withDamage ?? 0;
    const gritReroll = grit + characterContext.getAdjustment(GritReroll.id)

    const defence = 10 + (characterDisplay.attributes.find(a => a.id == "ref")?.current ?? 0) + characterContext.getAdjustment(Defence.id); // Change to current
    let armour = (characterDisplay.outfits.find(outfit => outfit.isWorn)?.armor ?? 0) + characterContext.getAdjustment(ArmorStat.id);
    let armourAtZero = (characterDisplay.outfits.find(outfit => outfit.isWorn)?.armorAtZero ?? 0) + characterContext.getAdjustment(ArmorAtZeroStat.id);
    

    const adjustDamage = (amount: number) => {
        setDamage(Math.min(Math.max(character.enduranceDamageTaken + amount, 0), enduranceMax));
    }
    
    return <>
        <Box>
            <Grid marginBottom={1} container spacing={1}>
                <Grid item xs={4} sm={4}>
                    <LabelBox>Defence</LabelBox>
                    <Typography fontSize={20} textAlign={"center"}>{defence}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox>Cover</LabelBox>
                    <Typography fontSize={20} textAlign={"center"}>{defence}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox>Armour</LabelBox>
                    <Typography fontSize={20} textAlign={"center"}>{armour} ({armourAtZero} at 0 End)</Typography>
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Box height="26px" display="flex" marginBottom="2px">
                        <ButtonFlat  onClick={() => adjustDamage(1)}>-</ButtonFlat>
                        <OuterBox>
                            <InnerBox>
                                <EnduranceBar amount={endurance/enduranceMax*100} />
                                <Label>{endurance}/{enduranceMax} ({recovery})</Label>
                            </InnerBox>
                        </OuterBox>
                        <ButtonFlat onClick={() => adjustDamage(-1)}>+</ButtonFlat>
                    </Box>
                    <LabelBox><EnduranceDamageIcon fill={theme.palette.primary.contrastText} height="1.2em" /><Box marginLeft="4px">Endurance</Box> (Recovery)</LabelBox>

                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox>Resistance</LabelBox>
                    <Typography fontSize={20} textAlign={"center"}>-</Typography>
                </Grid>
            </Grid>
        </Box>
    </>
}

export default CombatStatsView;